export let formReducer = (
  state = {
    email: null,
    token: null,
    inviteActive: true,
    action: 'invite',
    isFetching: false,
    error: null,
    data: []
  },
  action
) => {

  // toggle inviteActive action
  if (action.type === `TOGGLE_INVITE`) {
    return Object.assign({}, state, {
      inviteActive: !state.inviteActive,
    })
  }

  // toggle invite action
  if (action.type ===`ACTION_INVITE`) {
    return Object.assign({}, state, {
      action: 'invite'
    })
  }

  // toggle validate action
  if (action.type ===`ACTION_VALIDATE`) {
    return Object.assign({}, state, {
      action: 'validate'
    })
  }

  // toggle reset action
  if (action.type ===`ACTION_RESET`) {
    return Object.assign({}, state, {
      action: 'reset'
    })
  }

  // updateEmail action
  if (action.type === `UPDATE_EMAIL`) {
    return Object.assign({}, state, {
      email: action.email,
    })
  }

  // updateToken action
  if (action.type === `UPDATE_TOKEN`) {
    return Object.assign({}, state, {
      token: action.token,
    })
  }

  // start request
  if (action.type === `START_REQUEST`) {
    return Object.assign({}, state, {
      isFetching: true,
    })
  }

  // end request
  if (action.type === `END_REQUEST`) {
    return Object.assign({}, state, {
      isFetching: false,
      data: action.data
    })
  }

  // an error occurred
  if (action.type === `FORM_ERROR`) {
    return Object.assign({}, state, {
      isFetching: false,
      error: action.error
    })
  }

  // close message box
  if (action.type === `CLOSE_MESSAGE`) {
    return Object.assign({}, state, {
      error: null,
      data: []
    })
  }

  // return state
  return state

}
