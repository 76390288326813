export let authorizeReducer = (
  state = {
    authorized: false,
    user: []
  },
  action
) => {

  // user is authorized
  if (action.type === `AUTHORIZED`) {
    return Object.assign({}, state, {
      authorized: true,
      user: action.user
    })
  }

  // user is not authorized
  if (action.type === `NOT_AUTHORIZED`) {
    return Object.assign({}, state, {
      authorized: false,
      user: []
    })
  }

  return state
}
