// import createStore
import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux'
import thunk from 'redux-thunk'

// import reducer
import rootReducer from './rootReducer'

// create and export the store
const createStore = () => reduxCreateStore(
  rootReducer,
  compose(applyMiddleware(thunk))
)
export default createStore
