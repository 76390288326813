import { combineReducers } from 'redux'
import { formReducer } from '../components/Subscribe/formReducer'
import { authorizeReducer } from '../components/Authorize/authorizeReducer'
import { videoNavigationReducer } from '../components/VideoNavigation/videoNavigationReducer'

const rootReducer = combineReducers({
  form: formReducer,
  authorized: authorizeReducer,
  videoNavigation: videoNavigationReducer
})

export default rootReducer
