export let videoNavigationReducer = (
  state = {
    video: null,
    videoModalOpen: false,
    ccEnabled: false
  },
  action
) => {

  // toggle video cc
  if (action.type === `TOGGLE_VIDEO_CC`) {
    console.log(state.ccEnabled)
    return Object.assign({}, state, {
      ccEnabled: !state.ccEnabled
    })
  }

  // subscribe to OPEN_VIDEO_MODAL
  if (action.type === `OPEN_VIDEO_MODAL`) {
    return Object.assign({}, state, {
      videoModalOpen: true,
      video: action.video
    })
  }

  // subscribed to CLOSE_VIDEO_MODAL
  if (action.type === `CLOSE_VIDEO_MODAL`) {
    return Object.assign({}, state, {
      videoModalOpen: false,
      video: null
    })
  }

  return state
}
